










import {Vue, Component, Prop} from "vue-property-decorator";
import AddressSiteType from "./indexType";
import {IssueFriendStore} from "@/views/Friend/IssueFriend/Store";

@Component({name: "AddressSite"})
export default class AddressSite extends Vue implements AddressSiteType{

    @Prop(String)
    city!:string
    get getCity(){
        return this.city || ''
    }

    handleNotLocation(){
        IssueFriendStore.setIssueAddress({
            state:"off",
            address:"不显示位置"
        })
        this.$router.go(-1)
    }
}

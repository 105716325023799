










import {Vue, Component, Prop} from "vue-property-decorator";
import LocationItemType from "@/views/Friend/FriendAddress/components/LocationItem/indexType";
import {IssueFriendStore} from "@/views/Friend/IssueFriend/Store";
import {IssueGroupStore} from "@/views/Group/IssueGroup/Store";

@Component({name: "LocationItem"})
export default class LocationItem extends Vue implements LocationItemType {

    handleClick(): void {
        let data = {
            state:"ok",
            address:this.getData && this.getData.name || this.getData.address,
            province:this.getData && this.getData.pname,
            city:this.getData && this.getData.cityname,
            district:this.getData && this.getData.adname,
            township:this.getData && this.getData.address,
            showSiteStr:this.getData && this.getData.name,
        }
        if ("state" in this.$route.query || this.$route.query["state"]) {
            switch ( this.$route.query["state"] ) {
                case "friend":
                    IssueFriendStore.setIssueAddress(data)
                    break;
                case "group":
                    IssueGroupStore.SetIssueGroupAddress(data)
                    break
            }
        }else{
            IssueFriendStore.setIssueAddress(data)
            IssueGroupStore.SetIssueGroupAddress(data)
        }
        this.$router.go(-1)
    }


    @Prop(Object)
    data!:any
    get getData(){ return this.data || {} }

    @Prop(Number)
    index!:number
    get getIndex(){ return this.index }

}



























import {Vue, Component, Emit, Watch} from "vue-property-decorator";
import MyLocationSearchType from "./indexType";
import {Toast} from "vant";

@Component({name: "MyLocationSearch"})
export default class MyLocationSearch extends Vue implements MyLocationSearchType{
    Value = ""

    handleClickSearch(){
        if ( this.Value.length <= 0 ){
            Toast.fail("请输入搜索关键字");
            return;
        }
        this.pullData( { state:"ok",value:this.Value } )
    }

    @Emit("pullData")
    pullData(data:any){
        return data
    }

    @Watch("Value")
    changeValue(newVal:string){
        if ( newVal.length <= 0 ){
            this.pullData({ state:"off",value:newVal })
        }
    }
}

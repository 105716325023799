


















import {Vue, Component, Watch, Prop} from "vue-property-decorator";
import LocationListType from "@/views/Friend/FriendAddress/components/LocationList/indexType";
import PullDownUpList from "@/components/ShareComponent/PullDownUp.vue"
import LocationItem from "@/views/Friend/FriendAddress/components/LocationItem/index.vue";
import ListBottom from "@/components/ShareComponent/ListBottom.vue";
import {FriendLocationStore} from "@/views/Friend/FriendAddress/Store";
import {locationDataType} from "@/views/Friend/FriendAddress/Store/indexType";
import {getLocation, getSearchLocation} from "@/views/Friend/FriendAddress/Server";

@Component({name: "LocationList",components:{ PullDownUpList,ListBottom,LocationItem }})
export default class LocationList extends Vue implements LocationListType{
    PullDown = false
    UpDownBool = false
    StopUp = false
    List:any[] = []

    mounted(){
        this.List = FriendLocationStore.getLocationList
    }

    handlePullDown(){
        this.PullDown = true
        this.handleUpData(true)
    }

    handleUpDown(){
        if ( this.StopUp )return;
        let upData = FriendLocationStore.getLocationUpData;
        upData.pageNo += 1
        FriendLocationStore.setLocationUpData( upData )
        this.UpDownBool = true
        this.handleUpData()
    }

    handleUpData(bool=false){
        if ( this.getState ){
            getSearchLocation(this.getSearchVal as string).then(res=>{
                this.PullDown = false
                this.UpDownBool = false
                if ( res.length < FriendLocationStore.getLocationUpData.pageSize )this.StopUp = true;
                if ( bool )
                    FriendLocationStore.setLocationList( res );
                else
                    FriendLocationStore.setLocationList( FriendLocationStore.getLocationList.concat(res) );
            })
        }else{
            getLocation().then(res=>{
                this.PullDown = false
                this.UpDownBool = false
                if ( res.length < FriendLocationStore.getLocationUpData.pageSize )this.StopUp = true;
                if ( bool )
                    FriendLocationStore.setLocationList( res );
                else
                    FriendLocationStore.setLocationList( FriendLocationStore.getLocationList.concat(res) );
            })
        }
    }

    @Prop(Boolean)
    state?:boolean
    get getState(){ return this.state }
    @Prop(String)
    value?:string
    get getSearchVal(){ return this.value }
    @Watch("getState")
    changeState(newVal:boolean){
        FriendLocationStore.setLocationUpData({ ...FriendLocationStore.getLocationUpData,pageNo:1 })
        this.handleUpData(true)
    }

    get getLocationList(){ return FriendLocationStore.getLocationList }
    @Watch("getLocationList")
    changeLocationList(newVal:any[]){
        this.List = newVal
    }

    get getAddress(){ return FriendLocationStore.getLocationData }
    @Watch("getAddress")
    changeAddress(newVal:locationDataType){
        FriendLocationStore.setLocationUpData({ ...FriendLocationStore.getLocationUpData,pageNo:1 })
        this.handleUpData(true)
    }
}















import {Component, Watch} from "vue-property-decorator";
import FriendAddressType, {dataType} from "@/views/Friend/FriendAddress/FriendAddress";
import HeadTop from "@/components/ShareComponent/HeadTop.vue";
import MyLocationSearch from "@/views/Friend/FriendAddress/components/SeachTitle/index.vue";
import AddressSite from "@/views/Friend/FriendAddress/components/AddressSite/index.vue";
import LocationList from "@/views/Friend/FriendAddress/components/LocationList/index.vue";
import ZoomPage from "@/impView/PageSafety";
import {getLocation} from "@/views/Friend/FriendAddress/util/Gd";
import {FriendLocationStore} from "@/views/Friend/FriendAddress/Store";
import {locationDataType} from "@/views/Friend/FriendAddress/Store/indexType";
import {getLocationSiteData} from "@/views/Friend/FriendAddress/Server";
import {IssueFriendStore} from "@/views/Friend/IssueFriend/Store";
import JsSDK from "@/views/Friend/FriendAddress/util/Wx";
import {IssueGroupStore} from "@/views/Group/IssueGroup/Store";

@Component({name: "FriendAddress",components:{ HeadTop,MyLocationSearch,AddressSite,LocationList }})
export default class FriendAddress extends ZoomPage implements FriendAddressType{
    title = "正在定位获取数据中..."
    state = false
    searchValue = ""
    data:dataType = {}

    mounted(){
        let bool = navigator.userAgent.toLowerCase().match(/MicroMessenger/i)
        if ( bool != null && bool[0] == "micromessenger" ){
            new JsSDK({ That:this })
        }else{
            getLocation(this)
        }
    }

    handleGetPullData(e:any){
        let { value } = e
        if ( e.state == 'ok' ){
            this.state = true
            this.searchValue = value
        }else{
            this.searchValue = value
            this.state = false
        }
    }

    handleUpData(){
        getLocationSiteData().then(res=>{
            this.data = res
        })
    }

    get getSite(){
        let siteData = this.data.addressComponent || {}
        if ( siteData.township ){
            if ( this.data["formatted_address"] ){
                return this.data["formatted_address"]?.split(siteData.township)[1]
            }
            return `${ siteData.district }-${ siteData.township }`
        }else if ( siteData.district ){
            return `${ siteData.city }-${ siteData.district }`
        }else if ( siteData.province ){
            return siteData.province
        }else if ( siteData.city ){
            return siteData.city
        }else{
            return this.data["formatted_address"]
        }
    }
    @Watch("getSite")
    changeGetSite(newVal:string){
        if ( newVal.length ){
            this.title = newVal;
        }
        if ("state" in this.$route.query || this.$route.query["state"]) {
            switch ( this.$route.query["state"] ) {
                case "friend":
                    IssueFriendStore.setIssueAddress({ ...IssueFriendStore.getIssueAddress,showSiteStr:newVal })
                    break;
                case "group":
                    IssueGroupStore.SetIssueGroupAddress({ ...IssueFriendStore.getIssueAddress,showSiteStr:newVal })
                    break
            }
        }else{
            IssueFriendStore.setIssueAddress({ ...IssueFriendStore.getIssueAddress,showSiteStr:newVal })
            IssueGroupStore.SetIssueGroupAddress({ ...IssueFriendStore.getIssueAddress,showSiteStr:newVal })
        }
    }

    get getAddress(){
        return FriendLocationStore.getLocationData
    }
    @Watch("getAddress")
    changeAddress(newVal:locationDataType){
        this.handleUpData()
    }
}
